<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Edit Operational Hours"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <div class="px-2">
        <validation-observer ref="updateOperationalHours">
          <ValidationProvider
            v-slot="{ errors }"
            name="Start Time"
            vid="screen-start-time"
            rules="required"
          >
            <b-form-group
              label-for="screen-start-time"
              label="Enter start time"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-timepicker
                id="screen-start-time"
                v-model="deviceData.op_start_time"
                placeholder="Screen start time"
                variant="primary"
                locale="en"
                :show-seconds="false"
                :state="startTimer"
                :hour12="false"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="End Time"
            vid="screen-end-time"
            rules="required"
          >
            <b-form-group
              label-for="screen-end-time"
              label="Enter end time"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-timepicker
                id="screen-end-time"
                v-model="deviceData.op_end_time"
                placeholder="Screen end time"
                variant="primary"
                locale="en"
                :show-seconds="false"
                :state="endTimer"
                :hour12="false"
                :disabled="!endTimeField"
                @input="compareTimes"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        </validation-observer>
      </div>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BFormTimepicker, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BSpinner,
    BFormTimepicker,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      AccountTypes,
      endTimer: null,
      startTimer: null,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      return this.data
    },
    endTimeField() {
      if (!this.deviceData.op_start_time) {
        return false
      }
      return true
    },
  },

  methods: {
    showOpTimeModal() {
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },

    compareTimes() {
      const [h1, m1, s1] = this.deviceData.op_start_time.split(':').map(Number)
      const [h2, m2, s2] = this.deviceData.op_end_time.split(':').map(Number)

      const date1 = new Date()
      date1.setHours(h1, m1, s1, 0)

      const date2 = new Date()
      date2.setHours(h2, m2, s2, 0)

      if (date1.getTime() > date2.getTime()) {
        this.endTimer = false
        this.startTimer = true
      } else if (date1.getTime() < date2.getTime()) {
        this.endTimer = true
        this.startTimer = true
      } else {
        this.result = 'Time 1 is equal to Time 2'
        this.endTimer = true
        this.startTimer = true
      }
    },
    splitTime() {
      const [hours, minutes] = this.deviceData.op_start_time.split(':')
      this.deviceData.op_start_time = `${hours}:${minutes}`
      const [hours1, minutes1] = this.deviceData.op_end_time.split(':')
      this.deviceData.op_end_time = `${hours1}:${minutes1}`
    },
    async submitData() {
      try {
        const success = await this.$refs.updateOperationalHours.validate()
        if (success) {
          this.dataLoad = true
          this.splitTime()
          const payload = {
            op_start_time: this.deviceData.op_start_time, op_end_time: this.deviceData.op_end_time, macAddress: this.deviceData.mac_address, userHasGroupId: this.deviceData.user_has_group.id,
          }
          if (this.userRole === this.AccountTypes.ADMIN) {
            const response = await this.$store.dispatch('devices/updateOperationalHoursAdmin', { payload })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showOpTimeModal()
              showToast('Operational Time Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          } else {
            const response = await this.$store.dispatch('devices/updateOperationalHoursUser', { payload })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showOpTimeModal()
              showToast('Operational Time Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          }
        } else {
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
        this.dataLoad = false
      }
    },
  },
}
</script>
