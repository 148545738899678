<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Device Properties"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <b-form-group
        ref="updateDevicePropsForm"
      >
        <b-form-group
          label-for="screen-image"
          label="Select screen image"
          class="mx-2"
        >
          <b-form-checkbox
            id="screen-image"
            v-model="deviceData.image_support"
            variant="primary"
            :value="true"
            :unchecked-value="false"
          >Image Support</b-form-checkbox>
        </b-form-group>

        <b-form-group
          label-for="screen-video"
          label="Select screen video"
          class="mx-2"
        >
          <b-form-checkbox
            id="lng"
            v-model="deviceData.video_support"
            variant="primary"
            :value="true"
            :unchecked-value="false"
          >Video Support</b-form-checkbox>
        </b-form-group>
      </b-form-group>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BButton, BOverlay, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BFormGroup,
    BSpinner,
    BFormCheckbox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      AccountTypes,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      return this.data
    },
  },

  methods: {
    showDevicePropsModal() {
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    async submitData() {
      try {
        this.dataLoad = true
        const payload = {
          imageSupport: this.deviceData.image_support, videoSupport: this.deviceData.video_support, macAddress: this.deviceData.mac_address, userHasGroupId: this.deviceData.user_has_group.id,
        }
        if (this.userRole === this.AccountTypes.ADMIN) {
          const response = await this.$store.dispatch('devices/updateDevicePropsAdmin', { payload })
          if (response.data.statusCode === 200) {
            this.$emit('refresh-data')
            this.showDevicePropsModal()
            showToast('Device Updated', response.data.message.toString(), 'success')
            this.dataLoad = false
          }
        } else {
          const response = await this.$store.dispatch('devices/updateDevicePropsUser', { payload })
          if (response.data.statusCode === 200) {
            this.$emit('refresh-data')
            this.showDevicePropsModal()
            showToast('Device Updated', response.data.message.toString(), 'success')
            this.dataLoad = false
          }
        }
      } catch (e) {
        this.$swal(e.response.data.message)
        this.dataLoad = false
      }
    },
  },
}
</script>
