<template>
  <div class="add-image">
    <validation-observer ref="addMediaForm">
      <feather-icon
        v-if="!imagesLength<=0"
        size="16"
        icon="XIcon"
        class="cursor-pointer text-white"
        @click="removeItem()"
      />
      <div
        class="upload-image-div d-flex justify-content-center align-content-center align-items-center"
        @dragover.prevent
      >
        <b-overlay
          :show="spinner"
          rounded="sm"
          no-fade
          variant="transparent"
          opacity="0"
        >
          <div
            class="pt-1"
            @drop="dragFileImage"
          >
            <span class="d-flex justify-content-center align-content-center">
              <b-img
                v-if="fileSrc.imageSrc.length === 0"
                :src="require('@/assets/images/simiicons/Image(2).svg')"
                alt="data.json"
              />
              <template v-else>
                <ImageWithOverlay
                  v-for="(imageSrc, index) in fileSrc.imageSrc"
                  :key="index"
                  :image-src="imageSrc"
                  @drop.prevent
                  @dragover.prevent
                  @remove="removeImageAtIndex(index)"
                />
              </template>
            </span>
            <small
              v-if="fileSrc.imageSrc.length === 0"
              style="color: white;"
              class="text-nowrap font-small-1 d-flex justify-content-center align-items-center"
            >Drag & drop Images here or</small>
            <b-form-group
              text-bolder
              class="label"
            >
              <validation-provider
                name="image"
              >
                <b-form-file
                  id="image"
                  v-model="fileData.file"
                  :hidden="true"
                  plain
                  class="pl-12"
                  accept="image/*"
                  multiple
                  @change="uploadImage($event)"
                />
                <span v-if="uploadPercentage > 0 && uploadPercentage < 100">
                  <b-progress
                    v-model="uploadPercentage"
                    max="100"
                  />
                </span>
                <div
                  v-if="fileSrc.imageSrc.length !== 0"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="d-flex justify-content-center align-items-center font-small-2 mt-1 mx-auto"
                    size="sm"
                    variant="danger"
                    @click="removeImage"
                  >
                    Remove
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    class="d-flex justify-content-center align-items-center font-small-2 mt-1 mx-auto"
                    @click="uploadImages"
                  >
                    Upload
                  </b-button>
                </div>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="d-flex justify-content-center align-items-center font-small-2 mx-auto"
                  @click="chooseImage"
                >
                  Browse
                </b-button>
              </validation-provider>
            </b-form-group>
          </div>
        </b-overlay>
      </div>
    </validation-observer>
  </div>
</template>
<script>
// import { required } from '@validations'
import {
  BFormGroup, BProgress, BFormFile, BImg, VBTooltip,
  BOverlay, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImageWithOverlay from './ImageWithOverlay.vue'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BButton,
    BFormFile,
    BOverlay,
    BFormGroup,
    BProgress,
    BImg,
    ValidationObserver,
    ValidationProvider,
    ImageWithOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    spinner: {
      required: true,
      type: Boolean,
    },
    imagesLength: {
      required: true,
      type: Number,
    },
  },
  emits: ['upload-image', 'close-overlay'],
  data() {
    return {
      required,
      typesOfImage: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
      fileSrc: {
        imageSrc: [],
      },
      imageLimit: 6,
      show: false,
      uploadPercentage: 0,
      fileData: {
        name: '',
        file: [],
        thumbnail: null,
      },
    }
  },
  computed: {
    canAddMoreImages() {
      // Calculate the remaining image slots
      const remainingSlots = this.imageLimit - this.fileSrc.imageSrc.length - this.imagesLength
      return remainingSlots > 0
    },
  },
  methods: {
    dragFileImage(e) {
      this.errorMsg = null
      const { files } = e.dataTransfer
      // Check if the number of selected files exceeds the limit
      if (files.length + this.imagesLength > this.imageLimit) {
        this.errorMsg = `You cannot select up to ${this.imageLimit - this.imagesLength} images`
        showToast('Image Limitation', this.errorMsg.toString(), 'error')
        this.fileData.file = []
        this.fileSrc.imageSrc = [] // Clear the imageSrc array
        return
      }

      // Loop through the selected files
      for (let i = 0; i < files.length; i += 1) {
        const obj = files[i]
        const { size, type } = obj
        if (this.typesOfImage.includes(type)) {
          if (size < 26214400) {
            // Append each selected file to the imageSrc array
            this.fileSrc.imageSrc.push(URL.createObjectURL(obj))
            // You can also store the files in another array if needed
            this.fileData.file.push(obj)
          } else {
            this.errorMsg = 'Max size of each file should be 25MB'
            showToast('Image Size', this.errorMsg.toString(), 'danger')
            this.fileData.file = []
            this.fileSrc.imageSrc = [] // Clear the imageSrc array
            return
          }
        } else {
          this.errorMsg = 'Please upload Images with type jpeg, png, jpg!'
          showToast('Image Type', this.errorMsg.toString(), 'danger')
          this.fileData.file = []
          this.fileSrc.imageSrc = [] // Clear the imageSrc array
          return
        }
      }
    },
    removeImageAtIndex(index) {
      // Remove the image and file at the specified index
      this.fileSrc.imageSrc.splice(index, 1)
      this.fileData.file.splice(index, 1)
    },
    chooseImage() {
      document.getElementById('image').click()
    },
    uploadImage(event) {
      this.errorMsg = null
      this.randomKey = Math.random()
      const { files } = event.target
      // Check if the number of selected files exceeds the limit
      if (files.length + this.imagesLength > this.imageLimit) {
        this.errorMsg = `You cannot select up to ${this.imageLimit - this.imagesLength} images`
        showToast('Image Limitation', this.errorMsg.toString(), 'danger')
        this.fileData.file = []
        this.fileSrc.imageSrc = []
        return
      }

      // Loop through the selected files
      for (let i = 0; i < files.length; i += 1) {
        const obj = files[i]
        const { size, type } = obj
        if (this.typesOfImage.includes(type)) {
          if (size < '26214400') {
            // Append each selected file to the imageSrc array
            this.fileSrc.imageSrc.push(URL.createObjectURL(obj))
            this.fileData.file.push(obj)
          } else {
            this.errorMsg = 'Max size of file should be 25MB'
            showToast('Image Size', this.errorMsg.toString(), 'danger')
            this.fileData.file = []
          }
        } else {
          this.errorMsg = 'Please upload Image having type jpeg, png, jpg!'
          showToast('Image Type', this.errorMsg.toString(), 'danger')
          this.fileData.file = []
          this.fileSrc.imageSrc = []
        }
      }
    },
    removeImage() {
      this.fileSrc.imageSrc = []
      this.fileData.file = []
      this.errorMsg = null
    },
    uploadImages() {
      this.$emit('upload-image', this.fileData.file)
    },
    removeItem() {
      this.$emit('close-overlay')
    },
  },
}
</script>

<style lang="scss">
.add-image{
  width: 95%;
  height: 100%;

}
.upload-image-div {
    margin: 5px;
    padding: 14px;
    height: 100%;
  }
.upload-thumbnail-div {
    margin: 5px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 26px;
    max-width: auto;
    min-width: auto;
    min-height: auto;
    max-height: auto;

  }
  .label {
    font-size: 14px;
    color: #5e5873;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .imageSrc {
    width: 70px;
    height: 70px;
    margin: 2px;
  }
</style>
