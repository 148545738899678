<template>
  <b-card
    id="main-card"
    no-body
  >
    <b-card-title class="pl-2 pt-1 mb-0">
      SSP INFO
    </b-card-title>
    <!-- nav-wrapper-class="card-header-1" -->
    <b-tabs
      :value="tabIndex"
      class="pl-1 pb-0 mb-0 card-header-1"
      nav-class="nav-class"
      pills
      card
    >
      <!-- VISTAR COLLECTION -->
      <b-tab
        class="p-0 pb-2 mt-0"
        title="Vistar"
        style="flex-grow: 1;"
        tag="span"
        @click="sspUpdate(0)"
      >
        <!-- @click="getSspData($route.params.deviceId, $route.params.userId, 'vistar')" -->
        <VistarSSpCard
          :loading-state="loadSspData"
          :device-data="getVistar"
        />
      </b-tab>
      <!-- HIVESTACK COLLECTION -->
      <b-tab
        title="HiveStack"
        class="p-0 pb-2 mt-0"
        style="flex-grow: 1;"
        tag="span"
        @click="sspUpdate(1)"
      >
        <!-- @click="getSspData($route.params.deviceId, $route.params.userId, 'hivestack')" -->
        <HiveStackSSpCard
          :loading-state="loadSspData"
          :device-data="getHiveStack"
        />
      </b-tab>
      <template #tabs-end>
        <div
          v-if="tabIndex===0"
          class="check"
          style="display: inline;"
        >
          <span
            class="p-0"
          >
            Vistar
          </span>
          <b-form-checkbox
            v-model="isVistarAdShow"
            size="sm"
            class="text-ssp"
            inline
            :disabled="toggleSwitchVistar"
            switch
            @change="updateVistarAds"
          />
        </div>
        <div
          v-else
          class="check"
          style="display: inline; align-items:end;"
        >
          <span
            class="p-0"
          >
            HiveStack
          </span>
          <b-form-checkbox
            v-model="isHivestackAdShow"
            size="sm"
            class="text-ssp ml-auto"
            inline
            :disabled="toggleSwitchHivestack"
            switch
            @change="updateHivestackAds"
          />
        </div>
      </template>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import VistarSSpCard from './VistarSSpCard.vue'
import HiveStackSSpCard from './HiveStackSSpCard.vue'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardTitle,
    VistarSSpCard,
    HiveStackSSpCard,
    BFormCheckbox,
  },

  /* eslint-disable */
  data() {
    return {
      loadSspData: false,
      vistar: 'vistar',
      hiveStack: 'hivestack',
      tabIndex: null,
      isVistarAdShow: Boolean,
      isHivestackAdShow:Boolean
    }
  },
  computed: {
    getHiveStack() {
      return this.$store.getters['devices/getHivestackData']
    },
    getVistar() {
      return this.$store.getters['devices/getVistarData']
    },
    toggleSwitchVistar() {
      if (!this.getVistar.is_configure) {
        return true
      } if (this.getVistar.is_configure && this.getVistar.is_vistar_update) {
        return true
      }
      return false
    },
    toggleSwitchHivestack() {
      if (!this.getHiveStack.is_configure) {
        return true
      } if (this.getHiveStack.is_configure && this.getHiveStack.is_hivestack_update) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.getSspData(this.$route.params.deviceId, this.$route.params.userId, 'both')
    this.tabIndex = this.$store.getters['devices/getTabState']
  },
  methods: {
    turnOn() {
      this.loadSspData = !this.loadSspData
    },
    sspUpdate(id){
      this.$store.dispatch('devices/setTabState', id)
      this.tabIndex=id
    },
    linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },
    updateHivestackAds(){
      const success=this.$store.dispatch('devices/hivestackAdUpdate',{hivestackAdUpdate:this.isHivestackAdShow, userId: this.$route.params.userId, deviceId: this.$route.params.deviceId})
      if(success) {
        showToast("Successfull","Hivestack get Updated","success")
      } else{
        showToast('Bad Request' , "Ads don't get Updated", 'danger')
      }
    },
    updateVistarAds(){
      const success = this.$store.dispatch('devices/vistarAdUpdate', { vistarAdUpdate: this.isVistarAdShow, userId: this.$route.params.userId, deviceId: this.$route.params.deviceId })
      if (success) {
        showToast("Successfull","Vistar get Updated","success")
      } else {
        showToast('Bad Request', "Ads don't get Updated", 'danger')
      }
    },
    async getSspData(deviceId, userId, sspType) {
      this.turnOn()
      try {
        const success = await this.$store.dispatch('devices/getDeviceSSpData', { deviceId, sspType, userId })
        if (success) {
          if(sspType==='hivestack'){
            this.isHivestackAdShow= this.getHiveStack.is_hivestack_ad_show
          }else if(sspType==="vistar"){
            this.isVistarAdShow = this.getVistar.is_vistar_ad_show
          }
            this.isHivestackAdShow= this.getHiveStack.is_hivestack_ad_show
            this.isVistarAdShow = this.getVistar.is_vistar_ad_show
          this.turnOn()
        } else {
          this.turnOn()
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (statusCode === 500) {
          showToast('Internal Network Error', message.toString(), 'danger')
        } else {
          showToast('Authorization Error', message.toString(), 'danger')
        }
      }
    },
  },
}
/* eslint-enable */
</script>
<style lang="scss" scoped>

#main-card {
  .card-header-1 ::v-deep {
    div.card-header {
      padding-bottom: 0px !important;
      display: flex !important;
      justify-content: space-between !important;
      ul.nav {
        list-style: none;
        display: flex !important;
        padding: 0; /* Remove default padding */
        align-items: baseline;
        width: 100%;
        div.check {
          margin-top: 5px;
          margin-left: auto;
        }
      }
    }
  }

  .text-ssp {
    padding-bottom: 8px;
  }

  ::v-deep {
    .text-ssp {
      label.custom-control-label {
        padding: 0px !important;
      }
    }
  }
}
</style>
