<template>
  <div>
    <b-overlay
      :show="showOverlay"
    >
      <b-img
        :src="imageSrc"
        alt="Image"
        class="imageSrc"
        @drop.prevent
        @dragover.prevent
        @mouseover="showOverlay = true"
        @mouseleave="startOverlayHideTimer"
      />

      <!-- Overlay content, including the icon -->
      <!-- <div
        ref="dialog"
        tabindex="-1"
        role="dialog"
        aria-modal="false"
        aria-labelledby="form-confirm-label"
        class="text-center p-3"
      > -->
      <template #overlay>
        <feather-icon
          icon="Trash2Icon"
          @click="removeImage"
        />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BImg, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BOverlay,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,

    }
  },
  methods: {
    removeImage() {
      this.$emit('remove')
    },
    startOverlayHideTimer() {
      if (this.overlayHideTimer) {
        clearTimeout(this.overlayHideTimer) // Clear the previous timer
      }
      this.overlayHideTimer = setTimeout(() => {
        this.showOverlay = false // Hide the overlay after a short delay
        this.overlayHideTimer = null // Reset the timer
      }, 3000) // Adjust the delay time as needed (200 milliseconds in this example)
    },
  },
}
</script>
<style lang="scss" scoped>
.imageSrc {
    width: 80px;
    height: 80px;
    margin: 2px;
  }
</style>
