<template>
  <section>
    <div class="single-device">
      <Teleport to="#header-buttons" />
      <div class="mt-2">
        <b-row
          class="match-height"
        >
          <b-col
            xl="12"
            lg="12"
            md="12"
          >
            <DeviceAnalytics
              :device-object="device"
              :get-loaded-device="getLoadedDevice"
              @load-device="updateSSP"
              @load-data="deviceDataReload"
              @referesh-data="onRefreshData"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            xl="12"
            lg="12"
            md="12"
          >
            <DeviceSSpCard
              v-if="currentUser.user_has_role[0].role.role==='admin'"
            />
          </b-col>
          <!-- <b-col
            xl="12"
            lg="12"
            md="12"
          >
            <DeviceTotalRevenue />
          </b-col>
          <b-col
            xl="12"
            lg="12"
            md="12"
            class="mt-3"
          >
            <DeviceRevenueTable />
          </b-col> -->
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
import Teleport from 'vue2-teleport'
// import { mapState } from 'vuex'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import DeviceAnalytics from '@/components/devices-group/DeviceAnalytics.vue'
// import DeviceTotalRevenue from '@/components/devices-group/DeviceTotalRevenue.vue'
// import DeviceRevenueTable from '@/components/devices-group/DeviceRevenueTable.vue'
import DeviceSSpCard from '@/components/devices-group/DeviceSSpCard.vue'
import { showToast } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    DeviceAnalytics,
    Teleport,
    // DeviceTotalRevenue,
    // DeviceRevenueTable,
    DeviceSSpCard,
    BRow,
    BCol,
  },

  async beforeRouteUpdate(to, from, next) {
    if (to.name === 'single-device-admin') {
      /* eslint-disable */
      to.meta.pageTitle = to.params.deviceName
      to.meta.breadcrumb[2].text = to.params.deviceName
      next()
       this.$forceUpdate()
    } else if (to.name === 'single-device-user') {
      to.meta.pageTitle = to.params.deviceName
      to.meta.breadcrumb[1].text = to.params.deviceName
        next()
        this.$forceUpdate()
      /* eslint-enable */
    }
  },
  data() {
    return {
      getLoadedDevice: false,
      device: {
        ssp_opt_in: Boolean,
        id: Number,
        device_address: String,
        impressions: String,
        status: String,
        contact_email: String,
        images: Array,
        op_start_time: String,
        op_end_time: String,
        height: Number,
        width: Number,
        cpm: String,
        name: String,
        last_upadated_date: String,
        video_support: Boolean,
        image_support: Boolean,
        latitude: Number,
        longitude: Number,
        user_has_group: {
          name: String,
        },
        venue: {
          type: {
            venue_type_name: String,
          },
        },
      },
      AccountTypes,
    }
  },

  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    singleDevice() {
      return this.$store.getters['devices/getDevice']
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  watch: {
    '$route.params': {
      async handler(params) {
        const { userId } = params
        const { deviceId } = params
        const { id } = params
        if (this.currentUser.user_has_role[0].role.role === 'admin') {
        // Fetch data based on the updated route parameters (userId and deviceId)
          await this.getSingleDeviceAdmin(userId, deviceId)
          await this.$store.dispatch('devices/getDeviceSSpData', { deviceId, sspType: 'both', userId })
          // You can also update other data or trigger methods as needed
        } else {
          await this.getSingleDeviceUser(id)
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.currentUser.user_has_role[0].role.role === 'admin') {
      const { userId } = this.$route.params
      const { deviceId } = this.$route.params
      await this.getSingleDeviceAdmin(userId, deviceId)
    } else {
      const { id } = this.$route.params
      await this.getSingleDeviceUser(id)
    }
  },
  methods: {
    async getSingleDeviceAdmin(userId, deviceId) {
      this.getLoadedDevice = true
      try {
        const success = await this.$store.dispatch('devices/getDeviceAdmin', { userId, deviceId })
        if (success) {
          this.device = this.singleDevice
          this.getLoadedDevice = false
          showToast('Fetch Device', 'Fetch device successfully', 'success')
        }
      } catch (e) {
        this.$swal({
          title: '<span class="font-weight-bolder">Device not found!</span>',
          icon: 'error',
          html: `${e.message}`,
          showCloseButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          focusConfirm: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Go to Groups page',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonAriaLabel: 'Thumbs down',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            icon: 'primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$router.push('/groups')
          }
        })
      }
    },
    async getSingleDeviceUser(id) {
      this.getLoadedDevice = true
      try {
        const success = await this.$store.dispatch('devices/getUserDevice', { id })
        if (success) {
          showToast('Fetch Devices', 'Device fetch successfully', 'success')
          this.device = this.singleDevice
          this.getLoadedDevice = false
        }
      } catch (e) {
        this.$swal({
          title: '<span class="font-weight-bolder">Device not found!</span>',
          icon: 'error',
          html: `${e.message}`,
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: 'Go to Home page',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          customClass: {
            confirmButton: 'btn btn-primary',
            icon: 'primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$router.push('/')
          }
        })
      }
    },
    async updateSSP(value) {
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        this.getLoadedDevice = true
        this.$store.dispatch('devices/deviceSspOptionAdmin', { sspOptIn: value, userId: this.$route.params.userId, deviceId: this.device.id })
      } else {
        this.getLoadedDevice = true
        this.$store.dispatch('devices/deviceSspOptionUser', { sspOptIn: value, deviceId: this.device.id })
      }
      this.getLoadedDevice = false
    },
    deviceDataReload() {
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        const { userId } = this.$route.params
        const { deviceId } = this.$route.params
        this.getSingleDeviceAdmin(userId, deviceId)
      } else {
        const { id } = this.$route.params
        this.getSingleDeviceUser(id)
      }
    },
    onRefreshData() {
      this.device = { ...this.singleDevice }
      this.deviceDataReload()
      if (this.userRole === this.AccountTypes.ADMIN) {
        this.$store.dispatch('devices/getDeviceSSpData', { deviceId: this.device.id, sspType: 'both', userId: this.$route.params.userId })
      }
    },
  },

}

</script>
