<template>
  <div id="modal">
    <!-- hide-footer
    hide-header -->
    <b-modal
      v-model="showModal"
      centered
      ok-only
      ok-variant="outline-danger"
      ok-title="Close"
      modal-class="modal-danger"
      title="Delete Image"
    >
      <div>
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="images"
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(image)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item"
                />
              </template>
            </b-media>
          </template>
          <template #cell(actions)="data">
            <b-avatar
              variant="danger"
              size="32"
              button
              @click="deleteImage(data)"
            >
              <feather-icon
                :id="`delete-icon-${data.index}`"
                icon="Trash2Icon"
                size="16"
                variant=""
              />
              <!-- class="cursor-pointer" -->
              <b-tooltip
                title="Delete Image"
                class="cursor-pointer"
                :target="`delete-icon-${data.index}`"
              />
            </b-avatar>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BMedia, BAvatar, BTable, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BMedia,
    BAvatar,
    BTable,
    BTooltip,
  },
  props: {
    images: {
      type: [Array, Function, Object],
      required: true,
    },
  },
  emits: ['delete-image'],
  data() {
    return {
      showModal: false,
      tableColumns: [
        { key: 'image' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    show() {
      this.showModal = !this.showModal
    },
    deleteImage(imageFile) {
      this.$emit('delete-image', imageFile)
    },
  },

}
</script>
