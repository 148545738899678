<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Screen Info"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <validation-observer ref="updateDeviceInfoForm">
        <b-form-row class="p-4">
          <b-col
            lg="6"
            xl="6"
            md="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="device-email"
              vid="device-email"
              rules="required|email"
            >
              <b-form-group
                label-for="device-email"
                label="Enter Screen mail"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="device-email"
                  v-model="deviceData.contact_email"
                  placeholder="Enter secondary screen email"
                />
              </b-form-group>
              <b-form-invalid-feedback
                class="ml-2"
                :state="errors.length > 0 ? false:null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </b-col>
          <b-col
            lg="6"
            md="6"
            xl="6"
            sm="12"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Screen Phone Number"
              vid="screen-phone"
              rules="required"
            >
              <b-form-group
                label="Enter screen secondary phone number"
                label-for="screen-phone"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <VuePhoneNumberInput
                  v-model="deviceData.number"
                  valid-color="#005A9C"
                  :default-country-code="numberOrigin.country"
                  @update="isValid"
                />
                <span
                  class="text-danger"
                >{{ errors.length > 0 ? errors[0] : errorMessage }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-form-row>
      </validation-observer>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BFormRow, BCol,
  BFormInput, BFormInvalidFeedback, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import parsePhoneNumber from 'libphonenumber-js'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.au'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
    BFormRow,
    BCol,
    VuePhoneNumberInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      AccountTypes,
      email,
      errorMessage: '',
      isValidNumber: true,
      validNumber: '',
      numberOrigin: '',
    }
  },
  computed: {
    deviceData() {
      return this.data
    },
    numberCountry() {
      return this.deviceData.number
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  watch: {
    numberCountry(number) {
      if (number[0] === '+') {
        this.numberOrigin = parsePhoneNumber(number)
      }
    },
  },
  created() {
    this.getCountriesList()
  },
  methods: {
    showDeviceModal() {
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    async getCountriesList() {
      await this.$store.dispatch('devices/getAllCountries')
    },
    isValid(data) {
      if (!data.isValid) {
        this.errorMessage = 'Please enter a Valid Number'
        this.isValidNumber = data.isValid
      } else {
        this.errorMessage = ''
        this.validNumber = data
        this.isValidNumber = data.isValid
      }
    },
    async submitData() {
      try {
        const success = await this.$refs.updateDeviceInfoForm.validate()
        if (success && this.isValidNumber) {
          this.dataLoad = true
          const payload = {
            contact_email: this.deviceData.contact_email,
            number: this.validNumber.formattedNumber,
            macAddress: this.deviceData.mac_address,
          }
          if (this.userRole === this.AccountTypes.ADMIN) {
            const userHasGroupId = this.$route.params.userId
            const response = await this.$store.dispatch('devices/updateDeviceDataAdmin', { payload, userHasGroupId })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showDeviceModal()
              showToast('Device Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          } else {
            const response = await this.$store.dispatch('devices/updateDeviceDataUser', { payload })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showDeviceModal()
              showToast('Device Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          }
        } else {
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
        this.dataLoad = false
        this.showDeviceModal()
      }
    },
  },
}
</script>
