<template>
  <b-dropdown
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="outline-primary"
    class="my-class"
  >
    <template #button-content>
      {{ deviceName }}
    </template>
    <b-dropdown-item
      v-for="(deviceData, index) in getDevices"
      :key="index"
      @click="reloadPage(deviceData)"
    >
      <!-- style="overflow: auto;" -->
      <span
        class="align-middle"
      >
        {{ deviceData.name }}
      </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>

import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    userStatus: {
      type: String,
      default: '',
    },
  },
  emits: [
    'toggle-spinner',
  ],
  data() {
    return {
      deviceName: 'Screen Name',
    }
  },
  computed: {
    ...mapGetters('devices', ['getDevices']),
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    singleDevice() {
      return this.$store.getters['devices/getDevice']
    },
    singleDeviceAdmin() {
      return this.$store.getters['devices/getAdminDevice']
    },
  },
  created() {
    this.getAllDevices()
  },
  updated() {
    this.deviceName = this.$route.params.deviceName
  },
  methods: {
    getAllDevices() {
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        this.$store.dispatch('devices/getDevices')
      } else {
        this.$store.dispatch('devices/getDevices')
      }
    },
    reloadPage(device) {
      if (this.userStatus === 'admin') {
        const { id } = device
        const userId = device.user_has_group.id
        const { name } = device
        this.$router.push({
          name: 'single-device-admin',
          params: {
            userId,
            deviceId: id,
            deviceName: name,
          },
        })
      } else {
        const { id } = device
        const { name } = device
        this.$router.push({
          name: 'single-device-user',
          params: {
            id,
            deviceName: name,
          },
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
    .my-class ::v-deep
    .dropdown-menu {
    max-height: 50vh;
    overflow-y: scroll;
 }

</style>
