<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Location"
      class="p-4"
    >
      {{ cpm }}
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <validation-observer ref="updateCpmForm">
        <ValidationProvider
          v-slot="{ errors }"
          name="device-cpm"
          vid="cpm"
          rules="required|numeric|min_value:5"
        >
          <b-form-group
            label-for="cpm"
            label="Update CPM"
            :class="errors.length > 0 ? 'is-invalid' : null"
            class="mx-2"
          >
            <b-form-input
              id="cpm"
              v-model="cpm"
              placeholder="Enter CPM"
              @wheel="onWheel"
            />
          </b-form-group>
          <b-form-invalid-feedback
            class="ml-2"
            :state="errors.length > 0 ? false:null"
          >
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </validation-observer>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import { min_value } from 'vee-validate/dist/rules'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      min_value,
      numeric,
      AccountTypes,
      cpmValue: parseInt(this.$props.data.cpm, 10),
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      return this.data
    },
    cpm: {
      get() {
        return parseInt(this.deviceData.cpm, 10)
      },
      set(value) {
        const parsedValue = parseInt(value, 10)
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parsedValue)) {
          this.cpmValue = parsedValue
        }
      },
    },
  },

  methods: {
    showCpmModal() {
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    async submitData() {
      try {
        const success = await this.$refs.updateCpmForm.validate()
        if (success) {
          this.dataLoad = true
          const payload = { cpm: this.cpmValue, macAddress: this.deviceData.mac_address, userHasGroupId: this.deviceData.user_has_group.id }
          if (this.userRole === this.AccountTypes.ADMIN) {
            const response = await this.$store.dispatch('devices/updateDeviceCpmAdmin', { payload })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showCpmModal()
              showToast('CPM Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          } else {
            const response = await this.$store.dispatch('devices/updateDeviceCpmUser', { payload })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showCpmModal()
              showToast('CPM Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          }
        } else {
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
      }
    },
  },
}
</script>
